<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0" fluid>
    <PageHeader
      header-text="Events & Conferences"
      regular-button-text="New Event"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="dialog = true"
    />

    <SearchFieldRowWithPagination
      search-field-placeholder="Search for events/conferences"
      :current-page-number="events_page_number"
      :current-page-size="events_page_size"
      :count-total="events_total"
      :page-sizes="[25, 50, 100]"
      :init-search-string="search_string"
      @next-page="get_events_next_page"
      @prev-page="get_events_prev_page"
      @change-page-size="events_change_page_size"
      @search="(search_string) => on_search(search_string)"
    />

    <EventsTable :events="events" @action="handleAction($event)" />

    <v-dialog v-model="dialog" scrollable max-width="560px" @click:outside="edited_events = {}">
      <EventsEdit
        v-if="dialog"
        :event-item="edited_events"
        :available-tags="tagNames"
        @save="save_events"
        @dismiss="dialog = false; edited_events = {}"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import EventsTable from "@/views/Channels/components/EventsTable";
import EventsEdit from "@/views/Channels/components/EventsEdit";
import {hasError} from "@/mixins/has_error";

export default {
  name: "Events",
  metaInfo: {
    title: 'Events and Conferences'
  },
  components: {
    EventsEdit,
    EventsTable,
    SearchFieldRowWithPagination,
    PageHeader
  },
  mixins: [ hasError ],
  data() {
    return {
      edited_events: {},
      dialog: false,
      events: [],
      events_total: 0,
      events_page_number: 1,
      events_page_size: 25,
      search_string: '',
      tags: [],
      tagNames: [],
    };
  },
  created() {
    this.get_available_events(this.$route.query.keyword);
    this.getTags();
  },
  methods: {
    async handleAction(action) {
      if (action.event.value === "delete") {
        await this.$rest.events.put_resource(action.item.id, {
          ...action,
          isArchived: true,
        })
        await this.get_available_events();
      } else if(action.event.value === "edit") {
        this.edited_events = {...action.item}
        await this.getTagsOfEvent();
        this.dialog = true;
      }
    },
    async on_search(search_string) {
      this.search_string = search_string;
      await this.get_available_events(search_string);
    },
    async save_events(event) {
      const data = {}
      Object.keys(event).forEach(key => {
        if (!event[key]) {
          return;
        }
        data[key] = event[key];
      })

      // parse tags
      data.tagItems = data.tagItems.map((tagName) => {
        const tagInstance = this.tags.find((tagItem) => tagItem.name.toLowerCase() === tagName.toLowerCase());

        return tagInstance
          ? { id: tagInstance.id }
          : { name: tagName };
      });

      if (data.id){
        await this.$rest.events.put_resource(data.id, data)
        this.$notifier.success("Successfully edited the event");
      } else {
        await this.$rest.events.post_resource(data)
        this.$notifier.success("Successfully added the event");
      }
      this.edited_events = {};
      this.dialog = false;
      await this.get_available_events();
      await this.getTags();
    },
    async events_change_page_size(size) {
      this.events_page_size = size;
      this.events_page_number = 1;
      await this.get_available_events();
    },
    async get_events_next_page() {
      this.events_page_number += 1;
      await this.get_available_events();
    },
    async get_events_prev_page() {
      this.events_page_number -= 1;
      await this.get_available_events();
    },
    async get_available_events(search_string) {
        if (search_string) {
          this.search_string = search_string;
        } else {
          this.search_string = "";
        }
        const resp = await this.$rest.events.get_collection({
          limit: this.events_page_size,
          page: this.events_page_number,
          name: this.search_string,
          sort: ['createdAt:desc'],
          isArchived: false,
          groups: ["tag_creative:get"],
        });
      this.events = resp.data.items;
      this.events_total = resp.data.totalCount;
    },
    async getTags() {
      const response = await this.$rest.tags.getCollection({
        fields: ['id', 'name'],
        ignorePagination: true,
      }).catch(this.showGenericError);

      if (!response.data) {
        return;
      }

      this.tags = [...response.data.items];
      this.tagNames = this.tags.map((tagItem) => tagItem.name);
    },
    async getTagsOfEvent() {
      const response = await this.$rest.events.getTags(this.edited_events.id)
          .catch(this.showGenericError);

      if (!response.data) {
        return;
      }

      this.edited_events.tags = response.data.items.map((eventTag) => eventTag.tag.name);
    },
  },
};
</script>

