<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      <div v-if="id !== undefined && id !== null">
        Edit Event/Conference
      </div>
      <div v-else>
        Create Event/Conference
      </div>
    </v-card-title>
    <v-divider v-if="$vuetify.breakpoint.xsOnly" />
    <v-card-text class="px-6 pt-5">
      <validation-observer ref="textInput">
        <v-row>
          <v-col cols="auto">
            <CustomTextInput
              v-model="name"
              header="Event Name"
              class="flex-grow-1"
              placeholder="Ex: Health Expo - Vegas"
              :maxlength="250"
              required
            />
          </v-col>
          <v-col cols="auto">
            <CustomTextInput
              v-model="eventType"
              header="Event Type"
              class="flex-grow-1"
              placeholder="Ex: Conference"
              :maxlength="250"
            />
          </v-col>
          <v-col cols="auto">
            <CustomDropdown
              v-model="selectedSource"
              header="Source"
              placeholder="Select source"
              :items="available_sources"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <CustomDatePicker
              :date="dateOfEvent"
              header="Event Date"
              placeholder="Event Date"
              class="mt--2"
              @input="(date) => (dateOfEvent = date)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <TagsInput
              v-model="tags"
              :items="availableTags"
              header="Tags"
              placeholder="Select / Add Tag(s) for this Creative"
            />
          </v-col>
        </v-row>
      </validation-observer>
      <v-card-actions class="d-flex justify-end align-center pa-0 mt-5">
        <div
          class="simple-text-button button--gray px-9"
          @click="$emit('dismiss')"
        >
          Cancel
        </div>

        <v-btn
          class="custom-button custom-button--blue px-15"
          height="34px"
          depressed
          @click="on_save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomDatePicker from "@/sharedComponents/CustomDatePicker";
import TagsInput from "@/sharedComponents/TagsInput";

export default {
  name: "EventsEdit",
  components: {
    TagsInput,
    CustomTextInput,
    CustomDropdown,
    CustomDatePicker
  },
  props:{
    eventItem:{
      default: () => ({})
    },
    availableTags: {
      type: Array,
      default: () => [],
    },
  },
  data(){
    return {
      available_sources: [],
      selectedSource : null,
      defaultSource : null,
      id: null,
      name: "",
      eventType: null,
      dateOfEvent: null,
      tags: [],
    }
  },
  created() {
    this.id = this.eventItem.id;
    this.name = this.eventItem.name;
    this.eventType = this.eventItem.eventType || null;
    this.dateOfEvent = this.eventItem.dateOfEvent || null
    this.tags.push(...(this.eventItem?.tags ?? []));
    if (this.eventItem.sourceId !== undefined) {
      this.selectedSource = this.eventItem.sourceId;
    }
    this.get_available_sources();
  },
  methods: {
    async on_save() {
      if (!await this.$refs.textInput.validate()) {
        return;
      }

      this.$emit('save', {
        id: this.id,
        name: this.name,
        eventType: this.eventType,
        source: this.selectedSource,
        dateOfEvent: this.dateOfEvent,
        tagItems: this.tags,
      });
    },
    async get_available_sources() {
      let sourceData = (await this.$rest.source.get_collection({
        ignorePagination: true,
        sort: ["name:asc"]
      })).data.items;
      this.available_sources = sourceData.map(i => ({label: i.name, value: i.id}));

      this.defaultSource = sourceData.find( ({ isDefault }) => isDefault === true ).id;
      if (!this.selectedSource) {
        this.selectedSource = this.defaultSource
      }
    },
  },
}
</script>
